let draw; // เพิ่มบรรทัดนี้ด้านบนของไฟล์
window.$ = require("jquery");
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import XYZSource from "ol/source/XYZ";
import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Overlay from "ol/Overlay";
import { toStringHDMS } from "ol/coordinate";
import { toLonLat, fromLonLat } from "ol/proj";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { LineString, Polygon } from "ol/geom";
import Draw from "ol/interaction/Draw";
import { unByKey } from "ol/Observable";
import { getArea, getLength } from "ol/sphere";
import {
  defaults as defaultControls,
  Attribution,
  FullScreen,
  MousePosition,
  OverviewMap,
  ZoomToExtent,
  ScaleLine,
  ZoomSlider,
  Control,
} from "ol/control";

//ฟังก์ชันสำหรับสร้างปุ่มควบคุม:
function createMeasureControl(measureType) {
  const button = document.createElement("button");
  if (measureType === "length") {
    button.innerHTML = '<i class="fas fa-ruler"></i>';
    button.title = "วัดระยะทาง";
  } else {
    button.innerHTML = '<i class="fas fa-draw-polygon"></i>';
    button.title = "วัดพื้นที่";
  }
  button.className = "measure-control-button";

  const element = document.createElement("div");
  element.className = `measure-control measure-${measureType}`;
  element.appendChild(button);

  button.addEventListener(
    "click",
    function () {
      addMeasureInteraction(measureType);
    },
    false
  );

  return new Control({ element: element });
}

var container = document.getElementById("popup");
var content = document.getElementById("popup-content");
var closer = document.getElementById("popup-closer");
/**
 * Create an overlay to anchor the popup to the map.
 */
const overlay = new Overlay({
  element: container,
  autoPan: true,
  autoPanAnimation: {
    duration: 250,
  },
});

closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};

// base map
// var linkgeoserver = "https://webgis.phuketcity.go.th/geoserver/gisdataPAO/wms";
var linkgeoserver = "https://webgis.phuketcity.go.th/geoserver/gisdataPAO/wms";

const layers = [
  new TileLayer({
    source: new OSM(),
    title: "osm",
    zIndex: 0,
    isBaseLayer: true,
    visible: true,
  }),
  // google xyz tile
  new TileLayer({
    source: new XYZSource({
      url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
    }),
    title: "googlemap",
    zIndex: 0,
    isBaseLayer: true,
    visible: false,
  }),
  // xyz tile
  new TileLayer({
    source: new XYZSource({
      url: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
    }),
    title: "Satellite",
    zIndex: 0,
    isBaseLayer: true,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:province",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.8,
    title: "province",
    zIndex: 1,
    inBaseLayer: false,
    visible: true,
  }),
  // อำเภอ
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:amphoe",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.8,
    title: "amphoe",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  //ตำบล
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:tambon",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.8,
    title: "tambon",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:well",
        TILED: true,
      },
      serverType: "geoserver",
      transition: 0,
    }),
    opacity: 1,
    title: "well",
    zIndex: 2,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:roadpao",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "roadpao",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:fad",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 1,
    title: "fad",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:roaddrr",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "roaddrr",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:Contour10m",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "Contour10m",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:hfs",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "hfs",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:roadmain",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "roadmain",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:emarket",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "emarket",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),  
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:emarket",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "emarket",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:schools",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "schools",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:sks",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "sks",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:cctv",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "cctv",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:ceo",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "ceo",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
  new TileLayer({
    source: new TileWMS({
      url: linkgeoserver,
      params: {
        LAYERS: "gisdataPAO:sapao",
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),
    opacity: 0.9,
    title: "sapao",
    zIndex: 1,
    inBaseLayer: false,
    visible: false,
  }),
];

//MousePosition
const mousePosition = new MousePosition();

// zoom slidere
const zoomslider = new ZoomSlider();
// scale line
const scaleLine = new ScaleLine();
// attribution
const attribution = new Attribution({
  collapsible: false,
  // collapsed: true
});
// full screen
const fullScreen = new FullScreen();

const center = fromLonLat([99.948565, 6.844064], "EPSG:3857");
let view = new View({
  center: center,
  zoom: 10,
});

// create map
const map = new Map({
  overlays: [overlay],
  target: "map",
  layers: layers,
  view: view,
  controls: defaultControls({
    attribution: false,
  }).extend([
    attribution,
    fullScreen,
    scaleLine,
    zoomslider,
    createMeasureControl("length"),
    createMeasureControl("area"),
  ]),
});

//คลิ๊กแล้วขึ้นพิกัด
// map.on("singleclick", (e) => {
//   var coordinate = e.coordinate;
//   console.log(coordinate);
//   content.innerHTML =
//     '<p style="color:black">พิกัด:</p><code>' + coordinate + "</code>";
//   overlay.setPosition(coordinate);
// });

// attribution
function checkSize() {
  var small = map.getSize()[0] < 1000;
  attribution.setCollapsible(small);
  attribution.setCollapsed(small);
}
window.addEventListener("resize", checkSize);
checkSize();

(function ($) {
  "use strict";

  function createSkinBlock(colors, callback, noneSelected) {
    var $block = $("<select />", {
      class: noneSelected
        ? "custom-select mb-3 border-0"
        : "custom-select mb-3 text-light border-0 " +
          colors[0].replace(/accent-|navbar-/, "bg-"),
    });

    colors.forEach(function (color) {
      var $color = $("<option />", {
        class: (typeof color === "object" ? color.join(" ") : color)
          .replace("navbar-", "bg-")
          .replace("accent-", "bg-"),
        text: capitalizeFirstLetter(
          (typeof color === "object" ? color.join(" ") : color)
            .replace(/navbar-|accent-|bg-/, "")
            .replace("-", " ")
        ),
      });

      $block.append($color);

      $color.data("color", color);

      if (callback) {
        $color.on("click", callback);
      }
    });

    return $block;
  }

  var $sidebar = $(".control-sidebar");

  var $container = $("<div />", {
    class: "p-3 control-sidebar-content",
  });

  $sidebar.append($container);
  //ชั้นแผนที่

  $container.append(`
    <h5>ชั้นข้อมูลแผนที่</h5><hr class="mb-2"/>
    <h6>แผนที่ฐาน</h6>
    <input type="radio" value="osm" name="radio" checked> <label class="form-check-label">OSM</label>
    <br><input type="radio" value="googlemap" name="radio"> <label class="form-check-label">Google</label>
    <br><input type="radio" value="Satellite" name="radio"> <label class="form-check-label">Satellite</label><br><br>
    <h6>ชั้นแผนที่</h6>
    <input type="checkbox" value="province" id="defaultCheck1" checked> <label class="form-check-label">เขตจังหวัด</label>
    <br><input type="checkbox" value="amphoe" id="defaultCheck2"> <label class="form-check-label" for="defaultCheck2">เขตอำเภอ</label>
    <br><input type="checkbox" value="tambon" id="defaultCheck3"> <label class="form-check-label" for="defaultCheck3">เขตตำบล</label>
    <br><input type="checkbox" value="well" id="defaultCheck2"> <label class="form-check-label">บ่อน้ำตื้น 64</label>
    <br><input type="checkbox" value="fad" id="defaultCheck2"> <label class="form-check-label">ซั้งเชือก</label>
    <br><input type="checkbox" value="roadmain" id="defaultCheck3"> <label class="form-check-label"> ถนนทางหลวง</label>
    <br><input type="checkbox" value="roadpao" id="defaultCheck3"> <label class="form-check-label"> ถนน (อบจ.)</label>
    <br><input type="checkbox" value="roaddrr" id="defaultCheck3"> <label class="form-check-label"> ถนน (กรมทางหลวงชนบท.)</label>
    <br><input type="checkbox" value="Contour10m" id="defaultCheck3"> <label class="form-check-label">เส้นความสูง 10M</label>
    <br><input type="checkbox" value="hfs" id="defaultCheck3"> <label class="form-check-label">ราวจับอุ่นใจ</label>
    <br><input type="checkbox" value="emarket" id="defaultCheck3"> <label class="form-check-label">ผู้ประกอบการ e-market</label>
    <br><input type="checkbox" value="schools" id="defaultCheck3"> <label class="form-check-label">โรงเรียน</label>
    <br><input type="checkbox" value="sks" id="defaultCheck3"> <label class="form-check-label">สถานศึกษานำร่องฯ</label>
    <br><input type="checkbox" value="cctv" id="defaultCheck3"> <label class="form-check-label">กล้องวงจรปิด</label>
    <br><input type="checkbox" value="ceo" id="defaultCheck3"> <label class="form-check-label">นายก</label>
    <br><input type="checkbox" value="sapao" id="defaultCheck3"> <label class="form-check-label">สจ.</label>

  `);

  $("#measure-length").click(() => {
    addMeasureInteraction("length");
  });

  $("#measure-area").click(() => {
    addMeasureInteraction("area");
  });

  $(":radio").change((e) => {
    layers.forEach((l) => {
      if (l.values_.isBaseLayer === true) {
        if (l.values_.title === e.target.value) {
          l.setVisible(true);
        } else {
          l.setVisible(false);
        }
      }
    });
  });

  // overlay layers
  $(":checkbox").click((e) => {
    let chk = e.currentTarget.checked;
    let lyr = e.currentTarget.defaultValue;
    layers.forEach((l) => {
      if (l.values_.title === e.currentTarget.defaultValue) {
        if (e.currentTarget.checked === true) {
          l.setVisible(true);
        } else {
          l.setVisible(false);
        }
      }
    });
  });
})(jQuery);

//measurements
let sketch;
let measureTooltipElement;
let measureTooltip;

function createMeasureTooltip() {
  if (measureTooltipElement) {
    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
  }
  measureTooltipElement = document.createElement("div");
  measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: "bottom-center",
  });
  map.addOverlay(measureTooltip);
  console.log("Measure tooltip created and added to map"); // เพิ่มบรรทัดนี้
}

function formatLength(line) {
  const length = getLength(line);
  let output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " km";
  } else {
    output = Math.round(length * 100) / 100 + " m";
  }
  return output;
}

function formatArea(polygon) {
  const area = getArea(polygon);
  let output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " km<sup>2</sup>";
  } else {
    output = Math.round(area * 100) / 100 + " m<sup>2</sup>";
  }
  return output;
}

//การแสดงผลการวัด
function addMeasureInteraction(type) {
  console.log("Adding measure interaction:", type);

  // ลบ interaction ที่มีอยู่ก่อน
  map
    .getInteractions()
    .getArray()
    .slice()
    .forEach((interaction) => {
      if (interaction instanceof Draw) {
        map.removeInteraction(interaction);
      }
    });

  const source = new VectorSource();
  const vector = new VectorLayer({
    source: source,
    style: new Style({
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.2)",
      }),
      stroke: new Stroke({
        color: "#ffcc33",
        width: 2,
      }),
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: "#ffcc33",
        }),
      }),
    }),
  });

  map.addLayer(vector);

  const drawType = type === "area" ? "Polygon" : "LineString";
  const draw = new Draw({
    source: source,
    type: drawType,
    style: new Style({
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.2)",
      }),
      stroke: new Stroke({
        color: "rgba(0, 0, 0, 0.5)",
        lineDash: [10, 10],
        width: 2,
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.7)",
        }),
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
      }),
    }),
  });

  map.addInteraction(draw);

  let sketch;
  let measureTooltipElement;
  let measureTooltip;

  const formatLength = function (line) {
    const length = getLength(line);
    let output;
    if (length > 100) {
      output = Math.round((length / 1000) * 100) / 100 + " กม.";
    } else {
      output = Math.round(length * 100) / 100 + " ม.";
    }
    return output;
  };

  const formatArea = function (polygon) {
    const area = getArea(polygon);
    let output;
    if (area > 10000) {
      output = Math.round((area / 1000000) * 100) / 100 + " ตร.กม.";
    } else {
      output = Math.round(area * 100) / 100 + " ตร.ม.";
    }
    return output;
  };

  function createMeasureTooltip() {
    if (measureTooltipElement) {
      measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    }
    measureTooltipElement = document.createElement("div");
    measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
    measureTooltip = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      positioning: "bottom-center",
    });
    map.addOverlay(measureTooltip);
  }

  createMeasureTooltip();

  let listener;
  draw.on("drawstart", function (evt) {
    sketch = evt.feature;

    let tooltipCoord = evt.coordinate;

    listener = sketch.getGeometry().on("change", function (evt) {
      const geom = evt.target;
      let output;
      if (geom instanceof Polygon) {
        output = formatArea(geom);
        tooltipCoord = geom.getInteriorPoint().getCoordinates();
      } else if (geom instanceof LineString) {
        output = formatLength(geom);
        tooltipCoord = geom.getLastCoordinate();
      }
      measureTooltipElement.innerHTML = output;
      measureTooltip.setPosition(tooltipCoord);
    });
  });

  draw.on("drawend", function () {
    measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
    measureTooltip.setOffset([0, -7]);
    sketch = null;
    measureTooltipElement = null;
    createMeasureTooltip();
    unByKey(listener);
  });
}

function createMeasureTooltip() {
  if (measureTooltipElement) {
    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
  }
  measureTooltipElement = document.createElement("div");
  measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
  measureTooltip = new Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: "bottom-center",
  });
  map.addOverlay(measureTooltip);
}

function formatLength(line) {
  const length = getLength(line);
  let output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " กม.";
  } else {
    output = Math.round(length * 100) / 100 + " ม.";
  }
  return output;
}

function formatArea(polygon) {
  const area = getArea(polygon);
  let output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " ตร.กม.";
  } else {
    output = Math.round(area * 100) / 100 + " ตร.ม.";
  }
  return output;
}

draw.on("drawstart", function (evt) {
  console.log("Drawing started");
  sketch = evt.feature;

  listener = sketch.getGeometry().on("change", function (evt) {
    const geom = evt.target;
    let output;
    if (geom instanceof Polygon) {
      output = formatArea(geom);
    } else if (geom instanceof LineString) {
      output = formatLength(geom);
    }
    console.log("Calculated value:", output); // เพิ่มบรรทัดนี้
    measureTooltipElement.innerHTML = output;
    measureTooltip.setPosition(evt.coordinate);
  });
});
document
  .querySelector(".measure-length button")
  .addEventListener("click", function () {
    addMeasureInteraction("length");
  });

document
  .querySelector(".measure-area button")
  .addEventListener("click", function () {
    addMeasureInteraction("area");
  });
